<template>
  <div>
    <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onSubmit"
      v-on:answer="onAnswer"
      v-on:timer="onTimer"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
      :timer="timer"
      :timer-start-step="startStep"
    >
      <p v-if="timeUp">
        <span class="fh2">{{ params.lang.completed.timeup }}</span>
        <span class="f-section-text">{{
          params.lang.completed.timeupSub
        }}</span>
      </p>
      <template v-slot:complete>
        <div v-if="isValid">
          <span class="fh2">{{ params.lang.completed.title }}</span>
          <p class="f-section-text">
            {{ params.lang.completed.thankyou }}
          </p>
          <p class="f-section-text">
            {{ params.lang.completed.editOrSubmit }}
          </p>
        </div>
        <p>
          <span v-if="!isValid" class="f-section-text">
            {{ params.lang.completed.error }}
            <a href="https://community.simplicite.io/"
              >community.simplicite.io</a
            >
          </span>
        </p>
      </template>
      <div v-if="!validToken">
        <p>
          <span class="f-section-text">
            {{ params.lang.completed.error }}
            <a href="https://community.simplicite.io/"
              >community.simplicite.io</a
            >
          </span>
        </p>
        <p>
          <span class="f-section-text">
            {{ params.lang.invalidToken }}
          </span>
        </p>
      </div>
    </flow-form>
  </div>
</template>

<script>
// Import necessary components and classes
import simplicite from "simplicite";
import {
  FlowForm,
  QuestionModel,
  QuestionType,
  LanguageModel,
  ChoiceOption,
} from "@ditdot-dev/vue-flow-form";

const params = {};
params.generic = true;
let instanceUrl = window.location.origin;
/*if ("development" == process.env.NODE_ENV)
  instanceUrl = process.env.VUE_APP_URL;*/
const app = simplicite.session({ url: instanceUrl, debug: true });
await app.login({ username: "website", password: "kkWEW7Jx" });
const g = await app.getGrant();

let urlParams = new URLSearchParams(window.location.search);
let token = urlParams?.get("token");
let exams = [];
let ttc = 0; //time to complete
let userLang = "ENU";
let isTokenValid = false;
if (token) {
  let user = await app
    .getBusinessObject("QualUser")
    .search({ qualUsrToken: token });
  if (user[0]) {
    isTokenValid = true;
    userLang = user[0].usr_lang;
    params.userId = user[0].row_id;
    let userExams = await app
      .getBusinessObject("QualUsrExamSubjects")
      .search({ qualUsrexamUsrId: params.userId });
    let examEx = app.getBusinessObject("QualExamEx");

    for (const userExam of userExams) {
      let examId = userExam.qualUsrexamExamId;
      let examRow = await app
        .getBusinessObject("QualExam")
        .search({ row_id: examId });
      let examObj = examRow[0];

      let examName = examObj.qualExamName;
      let examDescription = examObj.qualExamDescription;
      ttc += examObj.qualExamAllocatedTime;

      let exam = {};
      let questions = [];

      if (examId) {
        let qsts = await examEx.search({ qualExamexExamId: examId });

        let start = { type: "QST_BREAK" };
        questions.push(start);

        for (const element of qsts) {
          let qst = {
            examTitle: element.qualExamexExamId__qualExamName,
            title: element.qualExamexExId__qualExQuestion,
            snippet: element.qualExamexExId__qualExSnippet,
            type: element.qualExamexExId__qualExAnswerType,
            enum: element.qualExamexExId__qualExChoicesEnumeration,
            id: element.qualExamexExId__qualExId,
          };
          questions.push(qst);
        }

        exam.questions = questions;
        exam.examTitle = examName;
        exam.examId = examId;
        exam.examDescription = examDescription;
      }

      exams.push(exam);
    }
  }
}

params.lang =
  "FRA" === userLang
    ? JSON.parse(g.getSystemParameter("QUAL_FRONT_FRA"))
    : JSON.parse(g.getSystemParameter("QUAL_FRONT_ENU"));

let output = [];
let tmp;
let unknown = false;
let exObjRowId = "";
let usrExObjIds = [];
let startStep = "start";
let timeToComplete = ttc;
let timer = timeToComplete != 0; //timeToComplete = 0 => No time to complete
let description = params.lang.start.description +
    (timer ? " Temps imparti : " + fancyTimeFormat(timeToComplete) : "")
  
if ("" != exams) {
  //Creates a 'SectionBreak' element that greets the user before starting an exam
  let start = new QuestionModel({
    id: "start",
    title: params.lang.start.title + " " + String.fromCodePoint("0x1F44B"),
    subtitle: params.lang.start.subtitle,
    description: description,
    type: QuestionType.SectionBreak,
    required: true,
  });

  //Add the SectionBreak to the pile of elements to display
  output.push(start);
  //For each exam
  for (let k = 0; k < exams.length; k++) {
    //get questions of exam
    let input = exams[k].questions;
    let firstSet = false;
    //for each question, create relevant type of question type
    for (const element of input) {
      if (element.type == "ENUM") {
        tmp = createEnumElement(element, exams[k]);
      } else if (element.type == "MULTI_ENUM") {
        tmp = createMultiEnumElement(element, exams[k]);
      } else if (element.type == "TXT") {
        tmp = createTxtElement(element, exams[k]);
      } else if (element.type == "FILE") {
        tmp = createFileElement(element, exams[k]);
      } else if (element.type == "QST_BREAK") {
        tmp = createBreakElement(exams[k]);
      }

      if (k == 0 && element.type != "QST_BREAK" && !firstSet) {
        //first item to start timer
        startStep = tmp.id;
        firstSet = true;
      }
      //add element to output (pile of questions)
      output.push(tmp);
    }
  }
}

let languageParams = new LanguageModel({
  enterKey: params.lang.ffModel.enterKey,
  shiftKey: params.lang.ffModel.shiftKey,
  continue: params.lang.ffModel.continue,
  pressEnter: params.lang.ffModel.pressEnter,
  otherPrompt: params.lang.ffModel.otherPrompt,
  submitText: params.lang.ffModel.submitText,
  placeholder: params.lang.ffModel.placeholder,
  percentCompleted: params.lang.ffModel.percentCompleted,
  multipleChoiceHelpTextSingle:
    params.lang.ffModel.multipleChoiceHelpTextSingle,
  multipleChoiceHelpText: params.lang.ffModel.multipleChoiceHelpText,
  longTextHelpText: params.lang.ffModel.longTextHelpText,
});

export default {
  components: {
    FlowForm,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      completed: false,
      language: languageParams,
      questions: output,
      isValid: !unknown,
      generic: params.generic,
      params: params,
      startStep: startStep,
      timeUp: false,
      timer: timer,
      validToken: isTokenValid,
    };
  },
  methods: {
    onTimer(time) {
      if (time === timeToComplete) {
        this.timeUp = true;
        this.onComplete(true);
        this.onSubmit();
        this.$refs.flowform.submitted = true;
        this.$refs.flowform.completed = true;
        this.$refs.flowform.questionList = "";
      }
    },
    async onAnswer(qA) {
      //When a sectionbreak is passed and the id associated to it contains "exam", it's that a user has started an exam.
      //The row is created in the backend
      if (qA.type == QuestionType.SectionBreak && qA.id.includes("exam")) {
        let usrExObj = await app.getBusinessObject("QualUserExam");
        let item = await usrExObj.getForCreate();
        item.qualUsrexamUsrId = params.userId;
        item.qualUsrexamExamId = qA.examId;
        await usrExObj.create(item);
        exObjRowId = usrExObj.getRowId();
        usrExObjIds.push(exObjRowId);
      }
      if (qA.type !== QuestionType.SectionBreak) {
        //if the type is other than a sectionbreak, it's a question -> set value in back
        //if multiple, answers will be an array
        let submittedValue = qA.multiple
          ? getAnwserFormArray(qA.answer)
          : qA.answer;
        let isFile = submittedValue[0] instanceof File;
        let fileAnswer;
        if (isFile) {
          fileAnswer = submittedValue[0];
        }
        let usrAnswerObj = await app.getBusinessObject("QualExUsr");
        let item = await usrAnswerObj.search({
          qualExusrUsrexamId: exObjRowId,
          qualExusrExamexId__qualExamexExId__qualExId: qA.id,
        });
        item = await usrAnswerObj.getForUpdate(item[0].row_id);
        item.qualExusrSubmitted = true;
        if (isFile)
          item.qualExusrImage = await new simplicite.Doc().load(fileAnswer);
        else item.qualExusrAnswer = submittedValue;
        usrAnswerObj.update(item).catch((err) => {
          app.error(err);
        });
      }
    },
    onComplete(completed) {
      // This method is called whenever the "completed" status is changed.
      this.completed = completed;
    },
    onSubmit() {
      // Set `submitted` to true so the form knows not to allow back/forward
      // navigation anymore.
      this.$refs.flowform.submitted = true;

      this.submitted = true;
      if (!unknown) {
        this.validateExams();
        //iterate through each exam
        exams.forEach((exam) => {
          let examQuestions = [];
          //get all questions of exam
          this.questions?.forEach((qst) => {
            if (
              qst.examId == exam.examId &&
              qst.type !== QuestionType.SectionBreak
            ) {
              return examQuestions.push(qst);
            }
          });
        });
      }
    },

    //Set status "DONE" on every exam passed by the user
    validateExams() {
      let obj = app.getBusinessObject("QualUserExam");
      usrExObjIds.forEach(function (id) {
        obj.getForUpdate(id).then(() => {
          obj.item.qualUsrexamEtat = "DONE";
          obj.update();
        });
      });
    },
  },
};

function createEnumElement(input, exam) {
  //create choice options
  let choices = [];
  let iChoices = input.enum.split("@@@");
  for (const element of iChoices) {
    let tmpChoice = new ChoiceOption({
      label: element,
    });
    choices.push(tmpChoice);
  }
  let e = getCodeComponent(input);
  //when all options have been created, create a MultipleChoice
  return new QuestionModel({
    examId: exam.examId,
    id: input.id,
    title: input.title,
    helpText: e,
    type: QuestionType.MultipleChoice,
    required: true,
    multiple: false,
    options: choices,
  });
}

function createMultiEnumElement(input, exam) {
  //create choice options
  let choices = [];
  let iChoices = input.enum.split("@@@");
  for (const element of iChoices) {
    let tmpChoice = new ChoiceOption({
      label: element,
    });
    choices.push(tmpChoice);
  }
  let e = getCodeComponent(input);
  //when all options have been created, create a MultipleChoice
  return new QuestionModel({
    examId: exam.examId,
    id: input.id,
    title: input.title,
    helpText: e,
    type: QuestionType.MultipleChoice,
    required: true,
    multiple: true,
    options: choices,
  });
}

function createTxtElement(input, exam) {
  let e = getCodeComponent(input);
  return new QuestionModel({
    examId: exam.examId,
    id: input.id,
    title: input.title,
    helpText: e,
    type: QuestionType.LongText,
    required: true,
  });
}

function createFileElement(input, exam) {
  return new QuestionModel({
    examId: exam.examId,
    id: input.id,
    title: input.title,
    subtitle: input.snippet,
    type: QuestionType.File,
    accept: "image/png",
    required: true,
  });
}

function createBreakElement(exam) {
  return new QuestionModel({
    id: "exam-" + exam.examId + "-break",
    examId: exam.examId,
    title: exam.examTitle,
    description: exam.examDescription,
    type: QuestionType.SectionBreak,
  });
}

function getAnwserFormArray(aArray) {
  return aArray.toString().replaceAll(",", "@@@");
}

function getCodeComponent(input) {
  if (!input.snippet) return null;
  let el = document.createElement("pre");
  let code = document.createElement("code");
  code.innerText = input.snippet;
  el.appendChild(code);
  let e = el.outerHTML;
  return e;
}

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}
</script>

<style>
/* Import Vue Flow Form base CSS */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css";
/* Import one of the Vue Flow Form CSS themes (optional) */
@import "~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css";
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
/* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
</style>
